@import 'main.scss';
.sectionContainer {
  padding-top: 64px;
  padding-bottom: 64px;
  @media screen and (min-width: 1000px) {
    padding-top: 128px;
    padding-bottom: 128px;
  }

  > div {
    display: flex;
    flex-direction: column;
    gap: 24px;
    @media screen and (min-width: 1000px) {
      gap: 48px;
    }
  }

  h2 {
    font-family: var(--font-avant);
    font-size: 35px;
    font-weight: 700;
    line-height: 45px;
    letter-spacing: 0.64px;
    text-align: center;

    @media screen and (min-width: 1000px) {
      font-size: 45px;
      line-height: 50px;
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
    max-width: 400px;
    margin: 0 auto;

    @media screen and (min-width: 1000px) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      gap: 24px;
      max-width: unset;
    }

    a {
      max-height: 70px;
      font-family: var(--font-sora);

      @media screen and (min-width: 1000px) {
        max-height: 75px;
      }

      @media screen and (min-width: 1200px) {
        max-height: 88px;
      }
    }
  }
}
