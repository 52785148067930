@import 'main.scss';
.sectionContainer {
  height: auto;
  width: 100%;
  padding: 100px 0px 0px 0px;
  overflow: hidden;

  @media screen and (min-width: 1000px) {
    min-height: 100dvh;
    padding: 140px 0;
  }

  h2 {
    color: var(--primary);
    display: flex;
    flex-direction: column;
    align-items: center;

    :first-child {
      padding: 0px 3px;

      @media screen and (min-width: 1000px) {
        padding: 0px 10px;
      }
    }
  }

  .description {
    margin: 40px auto;
    color: var(--primary);
    font-family: var(--font-sora);
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 22px;
    max-width: 500px;
    text-align: center;

    @media screen and (min-width: 1000px) {
      max-width: 650px;
      font-size: 20px;
      line-height: 28px;
    }

    @media screen and (min-width: 1200px) {
      max-width: 930px;
    }
  }
  :last-child {
    overflow: visible;
  }
}
