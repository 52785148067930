@import 'main.scss';
.burger {
  --BurguerBackground: var(--Primary-300, #4c2882);
  cursor: none;
  z-index: 99;
  position: relative;
  padding-right: 0px;
  transition: transform 0.3s ease-in-out;
  pointer-events: all;
  height: 41px;

  &::after,
  &::before {
    content: "";
    display: block;
    height: 3px;
    width: 48px;
    margin: auto;
    background: var(--BurguerBackground);
    position: relative;
    transition: transform 0.3s;

    @media screen and (min-width: 1000px) {
      height: 4px;
      width: 60px;
    }
  }

  &::after {
    top: -8px;

    @media screen and (min-width: 1000px) {
      top: -9px;
    }
  }

  &::before {
    top: 8px;

    @media screen and (min-width: 1000px) {
      top: 9px;
    }
  }

  @media screen and (hover: hover) {
    &:hover {
      transform: scale(1.05);
    }
  }
}

.burgerActive {
  &::after {
    transform: rotate(45deg);
    top: -3px;
    background: var(--white);
    transition:
      transform 0.3s ease-in-out,
      background 0.3s ease-in-out;
  }

  &::before {
    background: var(--white);
    transform: rotate(-45deg);
    top: 0px;
    transition:
      transform 0.3s ease-in-out,
      background 0.3s ease-in-out;
  }
}

.primaryTheme {
  &::after {
    background: var(--BurguerBackground);
    transition: background 0.3s ease-in-out;
  }

  &::before {
    background: var(--BurguerBackground);
    transition: background 0.3s ease-in-out;
  }
}

.secundaryTheme {
  &::after {
    background: var(--white);
    transition: background 0.3s ease-in-out;
  }

  &::before {
    background: var(--white);
    transition: background 0.3s ease-in-out;
  }
}
