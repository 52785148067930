@import 'main.scss';
.lens {
  border: 1px solid var(--primary);
  height: 44px;
  width: min-content;
  border-radius: 22px;
  display: none;
  align-items: center;
  justify-content: center;
  padding-left: 9px;
  padding-right: 5px;
  gap: 4px;
  overflow: hidden;

  @media screen and (min-width: 1000px) {
    display: flex;
  }

  @media screen and (hover: hover) {
    &:hover {
      transform: scale(1.05);
      transition: all 600ms;
    }
  }

  &.fullWidth {
    display: flex;
  }

  input {
    opacity: 0;
    background-color: transparent;
    font-family: var(--font-sora);
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    outline: none;
    color: var(--primary);
    margin-right: -172px;
    transition: all 800ms;

    &::placeholder {
      color: var(--primary);
    }
  }

  .icon {
    height: 24px;
    width: 24px;
  }
}

.active {
  padding: 10px 16px;
  max-width: 330px;
  width: fit-content;
  background-color: var(--white);

  @media screen and (hover: hover) {
    &:hover {
      transform: none;
    }
  }

  input {
    margin-right: 0;
    opacity: 1;
    transition: all 1s;
  }
}

.fullWidth {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  input {
    width: 100%;
  }
}
