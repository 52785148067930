@import 'main.scss';
.sectionTeam {
  background-color: var(--primary);
  min-height: 100vh;
  width: 100%;
  padding: 35px 0;

  @media screen and (min-width: 1000px) {
    padding: 170px 0 85px 0;
  }

  .containerTeam {
    height: 100%;
    width: 100%;
    position: relative;

    @media screen and (min-width: 1900px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    h2 {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      :first-child {
        color: transparent;
        background-image: linear-gradient(
          -90deg,
          rgba(255, 255, 255, 0.5) 50%,
          var(--white) 50%
        );
        display: flex;
        padding-left: 7px;

        &::after {
          content: "\2005";
        }
      }

      :last-child {
        -webkit-text-stroke: 3px var(--white);
        paint-order: stroke fill;
        color: var(--primary);

        @media screen and (min-width: 1000px) {
          -webkit-text-stroke: 5px var(--white);
        }

        @media screen and (min-width: 1200px) {
          -webkit-text-stroke: 6px var(--white);
        }
      }
    }

    .cardsContainer {
      width: 100%;
      height: calc(1243px + (50px * 3));
      background-color: transparent;
      position: relative;
      overflow: hidden;

      .cardsImageBackground {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        height: calc(1243px / 1.1125);
        opacity: 0.5;

        img {
          height: 100% !important;
          width: auto !important;
          max-width: none !important;
        }
      }

      .cardOpenOverlay {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        opacity: 0;
        width: 105%;
        height: 150%;
        background-color: var(--primary);
      }

      .cardsContent {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 32px;
        background-color: transparent;
        height: auto;
        width: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 50px;
        z-index: 2;

        @media screen and (min-width: 2238px) {
          padding: 32px 0;
        }

        .cardsRow {
          display: flex;
          align-items: center;
          gap: 30px;

          @media screen and (min-width: 1601px) {
            gap: 70px;
          }
        }

        .individual {
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
        }
      }
    }
  }
}

.card {
  display: flex;
  justify-content: center;
  position: absolute;
  perspective: 1000px;
  user-select: none;

  .card_inner {
    display: flex;
    justify-content: center;
    position: relative;
    transform-style: preserve-3d;
    border-radius: 28px;
    z-index: 4;

    .front_hor,
    .front_vert,
    .back {
      position: absolute;
      width: 100%;
      height: 100%;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      border-radius: 28px;
      overflow: hidden;
      z-index: 5;
    }

    .front_hor,
    .front_vert {
      background-color: var(--white);

      .front_vert_content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        height: 100%;
        width: 100%;
        text-align: center;
        position: relative;

        .front_text {
          display: flex;
          flex-direction: column;
          padding: 24px;
          width: 100%;
          align-self: flex-start;
          align-items: center;
          text-align: left;
          gap: 4px;
          z-index: 2;

          h2 {
            color: var(--primary);
            font-family: var(--font-avant);
            font-style: normal;
            font-weight: 700;
            font-size: 50px;
            line-height: 50px;
            text-transform: uppercase;
          }

          p {
            text-transform: uppercase;
            color: var(--primary);
            font-family: var(--font-avant);
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            font-size: 20px;
            text-align: center;
          }
        }

        .front_image {
          width: auto;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          position: absolute;
          inset: 0;

          img {
            height: auto;
            width: 110%;
            max-width: none !important;
          }
        }
      }

      .front_hor_content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        height: 100%;
        width: 100%;
        text-align: center;
        position: relative;
        overflow: hidden;

        .front_text {
          display: flex;
          flex-direction: column;
          padding: 32px;
          align-self: flex-start;
          align-items: flex-start;
          text-align: left;
          gap: 4px;
          height: auto;
          z-index: 2;

          h2 {
            color: var(--primary);
            font-family: var(--font-avant);
            font-style: normal;
            font-weight: 700;
            font-size: 50px;
            line-height: 50px;
            text-transform: uppercase;
          }

          p {
            text-transform: uppercase;
            color: var(--primary);
            font-family: var(--font-avant);
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            font-size: 20px;
          }
        }

        .front_image {
          position: absolute;
          inset: 0;

          img {
            width: auto;
            object-fit: cover;
          }
        }
      }
    }

    .back {
      background-color: var(--white);
      transform: rotateY(180deg);
      width: 100%;
      height: 100%;
      z-index: 8;

      .back_content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        opacity: 0;
        pointer-events: none;
        padding: 24px;

        .back_content_information {
          width: 100%;
          height: auto;
          display: flex;
          flex-direction: column;
          gap: 12px;

          div {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            div {
              display: flex;
              flex-direction: column;
              gap: 4px;
              width: 100%;

              h3 {
                color: var(--secondary);
                font-family: var(--font-avant);
                font-style: normal;
                font-weight: 700;
                font-size: 30px;
                line-height: 40px;
                text-transform: uppercase;
              }

              span {
                color: var(--primary);
                font-family: var(--font-avant);
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: 0.8px;
                text-transform: uppercase;
              }
            }

            .back_content_social {
              position: relative;
              width: 40px;
              height: 40px;
              pointer-events: all;
              z-index: 2;

              a {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 44px;
                height: 44px;
                border-radius: 50%;
                overflow: hidden;
                z-index: 5;
                transition: background-color 0.5s;

                @media screen and (hover: hover) {
                  &:hover {
                    background-color: #af2eff;

                    svg path {
                      fill: var(--white);
                    }
                  }
                }

                svg {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  width: 44px;
                  height: auto;
                }
              }
            }
          }

          p {
            font-size: 16px;
            line-height: 20px;
            font-family: var(--font-sora);
            font-weight: 300;
            color: var(--primary);
          }
        }
      }
    }
  }

  .glow {
    position: absolute;
    width: 100%;
    height: 102%;
    background-color: #4e2882;
    z-index: 3;
    pointer-events: none;
    border-radius: 28px;
  }
}
