@import 'main.scss';
.sectionContainer {
  min-height: 100vh;
  width: 100%;
  padding-top: 120px;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;

  @media screen and (min-width: 1001px) {
    padding-top: 160px;
    padding-bottom: 500px;
  }

  @media screen and (min-width: 1600px) {
    padding-top: 190px;
  }

  .canvas {
    width: 100vw;
    height: 600px;
    z-index: 22;
    pointer-events: none;

    @media screen and (min-width: 1001px) {
      height: 900px;
      position: absolute;
    }
  }

  .textContainer {
    display: flex;
    flex-direction: column;
    gap: 30px;

    @media screen and (min-width: 1000px) {
      gap: 50px;
    }

    h1 {
      color: var(--primary);
      max-width: 370px;
      overflow-wrap: break-word;
      display: flex;
      flex-wrap: wrap;

      @media screen and (min-width: 1000px) {
        max-width: 583px;
      }

      @media screen and (min-width: 1200px) {
        max-width: 700px;
      }

      @media screen and (min-width: 1601px) {
        max-width: 1000px;
      }
    }

    p {
      color: var(--primary);
      font-family: var(--font-sora);
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 22px;
      max-width: 500px;

      @media screen and (min-width: 1000px) {
        max-width: 600px;
        font-size: 20px;
        line-height: 28px;
      }

      @media screen and (min-width: 1200px) {
        max-width: 800px;
      }

      @media screen and (min-width: 1601px) {
        max-width: 1000px;
      }
    }
  }
}
