@import 'main.scss';
.card {
  background-color: var(--white);
  border-radius: 20px;
  width: 100%;
  height: 100%;
  font-family: var(--font-sora);
  overflow: hidden;
  transition: all 300ms;
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 1024px) {
    max-width: unset;
  }

  @media screen and (hover: hover) {
    &:hover {
      transform: scale(1.05);
      transition: all 300ms;
    }
  }

  > a {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .cover {
    width: 100%;
    max-height: 230px;
    object-fit: cover;
    display: inline;
    height: 100%;
    min-height: 230px;

    @media screen and (min-width: 1000px) {
      max-height: 180px;
      min-height: 180px;
    }
  }

  picture {
    height: 100%;
    max-height: 230px;
    min-height: 230px;
    @media screen and (min-width: 1000px) {
      max-height: 180px;
      min-height: 180px;
    }
  }

  .cardContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    padding: 16px 24px;
    justify-content: space-between;
    flex-grow: 1;

    .data {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
      align-items: flex-start;
      padding-top: 2px;

      .pill {
        padding: 8px 12px;
        border: 1px solid var(--Primary, #17082f);
        color: var(--Primary, #17082f);
        border-radius: 500px;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        height: auto;
        transition: all 300ms;
      }
    }

    h3 {
      font-family: var(--font-sora);
      font-size: 20px;
      font-weight: 400;
      line-height: 28px;
      color: #2f343d;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .info {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 16px;
    justify-content: space-between;
    padding-top: 6px;
    flex-wrap: wrap;

    a {
      display: flex;
      gap: 8px;
      align-items: center;
      height: fit-content;

      picture {
        height: fit-content;
      }
      .avatar {
        width: 32px;
        height: 32px;
        border-radius: 50%;
      }

      .author {
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        color: var(--Primary, #17082f);
        opacity: 0.7;
        transition: all 300ms;

        @media screen and (hover: hover) {
          &:hover {
            opacity: 1;
            transition: all 300ms;
          }
        }
      }
    }
  }
  .date {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: var(--Grey-medium, #8c8c8c);
  }
}
