@import 'main.scss';
.backButton {
  border: none;
  background: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  flex-direction: row;
  gap: 12px;
  user-select: none;

  &:disabled,
  button[disabled] {
    span {
      color: gray;
    }

    .svgDesktop,
    .svgMobile {
      path {
        stroke: gray;
      }
    }

    pointer-events: none;
  }

  span {
    font-size: 14px;
    color: var(--primary, #17082f);
    font-family: var(--font-sora);
    font-size: 14px;
    line-height: 16px;

    @media screen and (min-width: 1000px) {
      font-size: 26px;
      line-height: 33px;
    }
  }

  .svgDesktop {
    transition: all 0.3s ease;
    width: 46px;
    display: none;

    @media screen and (min-width: 1000px) {
      display: block;
    }
  }

  .svgMobile {
    transition: all 0.3s ease;
    display: block;

    @media screen and (min-width: 1000px) {
      display: none;
    }
  }

  @media screen and (hover: hover) {
    &:hover .svgDesktop,
    &:hover .svgMobile {
      transform: translateX(8px);
    }
  }

  &:active .svgDesktop,
  &:active .svgMobile {
    transform: scale(0.9);
  }

  .hoverLine {
    position: relative;
    color: black;

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 2px;
      bottom: -8px;
      left: 0;
      background-color: #000000;
      transform-origin: bottom right;
      transition: transform 0.25s ease-out;
    }
  }

  @media screen and (hover: hover) {
    &:hover .hoverLine::after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }
  }
}
