@import 'main.scss';
.transitionContainer {
  background-color: var(--primary);
  width: 100%;
  position: relative;
  padding: 25vh 0vh 15vh 0vh;
  transform-style: preserve-3d;

  .expertiseWrapper {
    margin-top: 20vh;
    pointer-events: all;
  }
}
