@import 'main.scss';
.navContainer {
  height: 100dvh;
  width: 100%;
  background-color: #17082f;
  position: fixed;
  right: 0;
  top: 0;
  color: white;
  font-family: var(--font-sora);

  .navInnerContainer {
    position: relative;
    width: 100%;
    margin: 0 auto;
    color: var(--white);
    display: flex;
    padding-bottom: 16px;
    flex-direction: column;
    justify-content: space-between;
    gap: 12px;
    height: 100%;
    align-items: center;

    & > :first-child {
      padding-top: 65px;
      margin: auto 0;

      @media screen and (min-width: 1000px) {
        padding-top: 0;
      }
    }

    @media screen and (min-width: 1000px) {
      padding-bottom: 42px;
      padding-top: 42px;
      align-items: flex-start;
      gap: 0px;

      & > :last-child {
        align-self: flex-end;
      }
    }

    @media screen and (min-width: 1600px) {
      padding-bottom: 60px;
      padding-top: 60px;
    }

    .navLinksContainer {
      display: flex;
      flex-direction: column;
      gap: 24px;
      width: 100%;
      align-items: center;

      @media screen and (min-width: 1000px) {
        align-items: flex-start;
      }

      .navLinks {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: fit-content;
        align-items: center;

        @media screen and (min-width: 1000px) {
          gap: 0;
          align-items: flex-start;
        }

        .link {
          display: flex;
          align-items: center;
          gap: 32px;

          .body {
            display: none;

            &.active {
              display: none;
            }

            @media screen and (min-width: 1000px) {
              display: block;
              max-width: 320px;
            }
          }

          .linkLabel {
            transition: 0.3s ease-in-out;
            color: var(--primary);
            -webkit-text-stroke: 2px var(--white);
            paint-order: stroke fill;
            width: fit-content;

            @media screen and (min-width: 1000px) {
              -webkit-text-stroke: 4px var(--white);
            }

            @media screen and (hover: hover) {
              &:hover {
                color: var(--white);
              }
            }

            &.active {
              color: var(--white);
            }
          }

          a {
            font-family: var(--font-avant);
            font-size: 38px;
            font-style: normal;
            font-weight: 700;
            line-height: 39px;
            letter-spacing: 0.8px;
            text-transform: uppercase;
            color: #fff;

            @media screen and (min-width: 1000px) {
              font-size: 85px;
              line-height: 100px;
            }

            @media screen and (min-width: 1600px) {
              font-size: 100px;
              line-height: 120px;
            }
          }

          .lineMask {
            overflow: hidden;
          }

          .lineMask p {
            font-size: 26px;
            font-weight: 300;
            line-height: 33px;
            margin: 0px;
            color: white;
          }
        }
      }

      button {
        width: 100%;
        max-width: 285px;
        margin-left: 0px;
      }
    }

    .navContactsContainer {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100%;

      @media screen and (min-width: 1000px) {
        flex-direction: row;
        justify-content: space-between;
      }

      .navContacts {
        display: flex;
        flex-direction: column;
        gap: 24px;
        border-bottom: 1px solid #d9d9d9;
        padding-bottom: 16px;

        @media screen and (min-width: 1000px) {
          gap: 32px;
          justify-content: space-between;
          flex-direction: row-reverse;
          align-items: center;
          border: none;
          padding-bottom: 0;
          width: 100%;
        }
      }

      .navDataItemContainer {
        display: flex;
        align-items: baseline;
        gap: 24px;
        justify-content: center;

        @media screen and (min-width: 1000px) {
          align-items: center;
          gap: 32px;
        }

        @media screen and (min-width: 1200px) {
          gap: 64px;
        }

        .navDataItem {
          display: flex;
          align-items: center;
          gap: 8px;

          svg {
            display: none;
            width: 24px;
            height: 24px;

            @media screen and (min-width: 1000px) {
              display: block;
            }

            @media screen and (min-width: 1200px) {
              width: 40px;
              height: 40px;
            }
          }

          @media screen and (min-width: 1600px) {
            flex-direction: row;
          }

          a {
            color: var(--background);
            font-family: var(--font-sora);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;

            @media screen and (min-width: 1000px) {
              font-size: 18px;
              line-height: 23px;
            }

            @media screen and (min-width: 1200px) {
              font-size: 22px;
              line-height: 26px;
            }
          }
        }
      }
    }

    .navSocialContainer {
      display: flex;
      align-items: center;
      gap: 48px;
      justify-content: center;

      @media screen and (min-width: 1000px) {
        display: none !important;
      }
    }
  }

  .navCurve {
    position: absolute;
    top: 0;
    left: -99px;
    width: 100px;
    height: 100%;
    fill: var(--primary);
    stroke: none;
  }
}

.navSocialContainerMagnetic {
  display: none !important;
  position: absolute;

  @media screen and (min-width: 1000px) {
    flex-direction: column;
    display: flex !important;
    gap: 24px;
    right: 32px;
    top: 50%;
    transform: translateY(-50%);
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0);
    height: 97px;
    width: 97px;
    border-radius: 50%;
    transition: background-color 0.5s;

    @media screen and (hover: hover) {
      &:hover {
        background-color: rgba(175, 46, 255, 0.6);
      }
    }
  }
}
