@import 'main.scss';
.sectionContainer {
  margin: 100px 0px;

  > div {
    @media screen and (min-width: 1200px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .infoContainer {
      max-width: 700px;

      @media screen and (min-width: 1000px) {
        max-width: 650px;
      }

      @media screen and (min-width: 1600px) {
        max-width: 800px;
      }

      section {
        @media screen and (min-width: 1000px) {
          max-width: 650px;
        }

        @media screen and (min-width: 1600px) {
          max-width: unset;
        }

        h3 {
          @media screen and (min-width: 1000px) {
            letter-spacing: 0px;
          }

          @media screen and (min-width: 1200px) {
            font-size: 55px;
            line-height: 65px;
          }
        }

        p {
          max-width: 500px;
          font-size: 20px;
          line-height: 28px;
          font-weight: 300;

          @media screen and (min-width: 1200px) {
            max-width: unset;
          }
        }
      }
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      gap: 48px;
      margin: 48px 0 60px 0;

      @media screen and (min-width: 1000px) {
        display: grid;
        grid-template-columns: repeat(2, 300px);
      }

      @media screen and (min-width: 1600px) {
        grid-template-columns: repeat(2, 350px);
      }

      li {
        display: flex;
        flex-direction: column;
        gap: 8px;
        max-width: 300px;

        @media screen and (min-width: 1200px) {
          max-width: 350px;
        }

        .blockNumber,
        .blockIcon {
          display: flex;
          flex-direction: column;
          font-family: var(--font-sora);

          span {
            font-size: 30px;
            font-weight: 700;
            line-height: 40px;
            letter-spacing: 0.64px;
            color: var(--secondary);

            @media screen and (min-width: 1000px) {
              font-size: 35px;
              line-height: 50px;
            }
          }

          h3 {
            font-size: 26px;
            font-weight: 600;
            line-height: 33px;
            color: var(--Primary-300, #4c2882);
          }
        }

        p {
          font-size: 16px;
          font-weight: 300;
          line-height: 22px;
          font-family: var(--font-sora);
        }

        .blockIcon {
          flex-direction: row;
          gap: 8px;
        }
      }
    }

    .picture {
      width: 100%;
      height: 100%;
      max-width: 352px;
      max-height: 378px;
      object-fit: cover;
      height: 100%;

      @media screen and (min-width: 1000px) {
        width: 380px;
        max-width: unset;
        max-height: unset;
      }

      @media screen and (min-width: 1200px) {
        width: 400px;
      }

      @media screen and (min-width: 1600px) {
        width: 530px;
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: 15px;
      }
    }
  }
}
