@import 'main.scss';
.flair {
  position: fixed;
  top: 0;
  left: 0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: var(--cursor-color);
  opacity: 0.1;
  z-index: 999;
  user-select: none;
  pointer-events: none;

  span {
    color: var(--white, #fff);
    font-family: var(--font-sora);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    opacity: 0;
    white-space: nowrap;
  }
}

.cursor {
  position: fixed;
  top: 0;
  left: 0;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: var(--cursor-color);
  z-index: 888;
  user-select: none;
  pointer-events: none;
  --cursor-size: 0;
  --cursor-content-visibility: hidden;
  --border-width: 0;
  --border-color: var(--white, #fff);
  --bg-color: var(--cursor-color);
  --bg-img: none;
  --bg-size: 100% 100%;
  text-transform: uppercase;
}

.cursor::after {
  content: attr(data-content);
  content-visibility: var(--cursor-content-visibility);
  width: var(--cursor-size);
  height: var(--cursor-size);
  border-radius: 50%;
  background-color: var(--bg-color);
  border: var(--border-width) solid var(--border-color);
  background-image: var(--bg-img);
  background-size: var(--bg-size);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  color: white;
  z-index: 999;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  user-select: none;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  color: var(--white, #fff);
  font-family: var(--font-sora);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  white-space: nowrap;
  transition:
    width 0.2s ease-out,
    height 0.2s ease-out,
    content-visibility 0.2s ease-out;
  background-color: 0.2 ease-out;
}
