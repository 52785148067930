@import 'main.scss';
.titleContainer {
  margin-bottom: 24px;
  padding-top: 150px !important;

  h1 {
    font-family: var(--font-avant);
    font-size: 55px;
    font-weight: 700;
    line-height: 60px;
    text-align: center;
    @media screen and (min-width: 1200px) {
      font-size: 70px;
      line-height: 80px;
    }
  }
}
