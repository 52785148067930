@import 'main.scss';
.card {
  width: 100%;
  max-width: 280px;
  background-color: var(--white);
  box-shadow: 4px 4px 30px 0px #00000080;
  padding: 32px;
  border-radius: 55px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  border-bottom: 8px solid var(--Primary-300, #4c2882);
  height: 100%;

  @media screen and (min-width: 1000px) {
    max-width: 300px;
  }

  .data {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  h3 {
    font-family: var(--font-avant);
    font-weight: 700;
    text-align: left;
    margin: 0;
    text-transform: uppercase;
    font-size: 45px;
    line-height: 55px;
    color: var(--primary);

    @media screen and (min-width: 1000px) {
      line-height: 60px;
      font-size: 50px;
    }
  }

  p {
    font-family: var(--font-sora);
    font-weight: 300;
    text-align: left;
    margin: 0;
    font-size: 16px;
    line-height: 22px;
    color: var(--primary);

    @media screen and (min-width: 1000px) {
      font-size: 20px;
      line-height: 28px;
    }
  }

  .skills {
    display: flex;
    flex-direction: column;
    gap: 16px;

    li {
      font-family: var(--font-sora);
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      text-align: left;
      position: relative;
      padding: 10px 12px;
      border-bottom: 1px solid #e0e0e0;
      color: #4c2882;
    }

    li:last-of-type {
      border-bottom: none;
    }
  }
}
