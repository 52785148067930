@import 'main.scss';
.sectionContainer {
  width: 100%;
  position: relative;

  height: 175vh;

  @media screen and (min-height: 601px) {
    height: calc(100vh + 100px);
  }

  @media screen and (min-width: 1000px) {
    height: 100vh;
    min-height: 800px;
  }

  .canvas {
    position: absolute;

    z-index: 4;

    pointer-events: none;

    display: flex;
    justify-content: center;
    align-items: center;

    bottom: -50px;
    right: 50%;
    transform: translateX(50%);

    width: 500px;
    height: 600px;

    @media screen and (min-width: 1001px) {
      transform: none;
      right: 0px;
      width: 600px;
      height: 600px;
    }

    @media screen and (min-width: 1601px) {
      width: 650px;
      height: 650px;
    }

    @media screen and (min-width: 1901px) {
      right: 200px;
      width: 700px;
      height: 700px;
    }
  }

  .textContainer {
    padding: 100px 0;
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 100%;

    @media screen and (min-width: 1000px) {
      width: max-content;
      height: 100vh;
      padding: 170px 0px 100px 0px;
    }

    h2 {
      width: 100%;
      pointer-events: none;

      :first-child {
        color: transparent;
        display: flex;
        background-image: linear-gradient(
          -90deg,
          rgba(255, 255, 255, 0.5) 50%,
          var(--white) 50%
        );
        padding-left: 5px;
      }

      .lastWords {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        :first-child {
          &::after {
            content: "\2005";
          }
        }

        :last-child {
          -webkit-text-stroke: 3px var(--white);
          paint-order: stroke fill;
          color: var(--primary);

          @media screen and (min-width: 1000px) {
            -webkit-text-stroke: 5px var(--white);
          }

          @media screen and (min-width: 1200px) {
            -webkit-text-stroke: 6px var(--white);
          }
        }
      }
    }

    .description {
      font-family: var(--font-sora);
      font-size: 16px;
      line-height: 22px;
      font-weight: 300;
      color: var(--white);

      width: 100%;

      @media screen and (min-width: 301px) {
        max-width: 352px;
      }

      @media screen and (min-width: 401px) {
        max-width: 500px;
        font-size: 20px;
        line-height: 28px;
      }

      @media screen and (min-width: 1001px) {
        max-width: 613px;
      }

      @media screen and (min-width: 1361px) {
        max-width: 901px;
      }
    }
  }

  .wave {
    width: 100%;

    position: absolute;
    bottom: 0px;
  }
}
