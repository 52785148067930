@import 'main.scss';
.sectionContainer {
  width: 100%;

  .videoContainer {
    margin: auto;
    // Estos calculos son para hacer el video responsivo en mobile y evitar que el pin se rompa
    height: calc((100vw - 48px) * 702px) / 414px;

    @media screen and (min-width: 400px) {
      height: 597px;
    }

    @media screen and (min-width: 1000px) {
      margin: unset;
      width: 100%;
      height: 100%;
    }

    @media screen and (min-width: 1600px) {
      padding: 0;
      max-width: 1700px;
    }

    video {
      width: 100%;
      max-width: 352px;
      height: 100%;
      border-radius: 20px;
      cursor: none;
      transform: scale(0.6, 0.6);
      // animation: fade-in-video linear forwards !important;
      // animation-timeline: view() !important;
      // animation-range: entry !important;

      @media screen and (min-width: 1000px) {
        max-width: 100%;
        height: 100%;
      }
    }
  }
}

@keyframes fade-in-video {
  to {
    transform: scale(1);
  }
}
