@import 'main.scss';
.bannerTextContainer {
  height: 100%;
  width: 100%;
  margin-bottom: 48px;

  .bannerTextWap {
    color: var(--white);
    font-family: var(--font-avant);
    background: linear-gradient(81.01deg, #17082f -7.75%, #af2eff 60.75%);
    padding: 32px;
    max-width: 932px;

    margin: 0 auto;
    display: flex;
    gap: 50px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    position: relative;
    @media screen and (min-width: 1000px) {
      gap: 24px;
    }

    > div {
      display: flex;
      flex-direction: column;
      gap: 8px;
      max-width: 530px;
      @media screen and (min-width: 1000px) {
        max-width: 650px;
      }
    }

    h2 {
      font-size: 45px;
      font-weight: 700;
      line-height: 50px;
    }
    p {
      font-family: var(--font-sora);
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
    }

    .buttonContainer {
      position: static;
      div button {
        display: flex;
      }
    }
  }
}
