@import 'main.scss';
.wrapper {
  height: 100vh;
  display: flex;
  padding-top: 100px !important;

  @media screen and (min-width: 1000px) {
    padding-top: 150px !important;
  }

  > div {
    display: flex;
    flex-direction: column;
    gap: 24px;
    text-align: left;

    @media screen and (min-width: 1000px) {
      gap: 48px;
    }
  }

  h1 {
    font-family: var(--font-avant);
    font-weight: 700;
    text-transform: uppercase;
    font-size: 28px;
    line-height: 30px;
    -webkit-text-stroke-width: 1px;
    color: var(--primary);

    @media screen and (min-width: 1000px) {
      font-size: 65px;
      line-height: 75px;
      -webkit-text-stroke-width: 2px;
      max-width: 1000px;
    }

    @media screen and (min-width: 1200px) {
      font-size: 80px;
      line-height: 80px;
      -webkit-text-stroke-width: 3px;
      max-width: 1040px;
    }

    @media screen and (min-width: 1600px) {
      font-size: 100px;
      line-height: 100px;
    }
  }
}
