@import 'main.scss';
.goodBoysContainer {
  width: 100%;
  height: 100vh;
  position: relative;
  padding: 100px 0;
  margin-top: 100px;

  @media screen and (min-width: 1001px) {
    padding-bottom: 450px;
  }

  .title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: initial;
    user-select: none;
    font-family: var(--font-avant);
    width: 100%;

    padding-bottom: 250px;

    @media screen and (min-width: 401px) {
      padding-bottom: 300px;
    }

    @media screen and (min-width: 1001px) {
      padding-bottom: 0px;
    }

    a {
      margin-top: 24px;
      z-index: 12;

      @media screen and (min-width: 1600px) {
        margin-top: 40px;
      }
    }

    h2 {
      color: var(--primary);

      :first-child {
        display: flex;
      }

      .lastWords {
        display: flex;
        align-items: center;

        :first-child {
          &::after {
            content: "\2005";
          }
        }
      }
    }

    .description {
      font-family: var(--font-sora);
      margin-top: 32px;
      color: var(--primary);
      max-width: 500px;
      width: 100%;
      font-weight: 300;
      font-size: 16px;
      line-height: 22px;

      @media screen and (min-width: 1000px) {
        line-height: 28px;
        font-size: 20px;
        margin-top: 40px;
      }
    }
  }

  .wavePath {
    width: 100%;

    path {
      fill: none;
      stroke: black;
      stroke-width: 2;
    }
  }
}
