@import 'main.scss';
.sceneDuck {
  width: 300px;
  height: 300px;

  @media screen and (min-width: 1001px) {
    width: 450px;
    height: 450px;
  }

  @media screen and (min-width: 1601px) {
    width: 650px;
    height: 650px;
  }
}
