@import 'main.scss';
.sectionContainer {
  background: var(--primary);
  position: relative;
  padding: 160px 0px;
  .titleSlider {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    gap: 48px;

    @media screen and (min-width: 1000px) {
      height: 100vh;
    }

    h3 {
      text-align: center;
      font-family: var(--font-avant);
      font-size: 32px;
      line-height: 40px;
      font-weight: 700;
      color: var(--background);
      letter-spacing: 0.04em;

      @media screen and (min-width: 1200px) {
        font-size: 45px;
        line-height: 50px;
      }
    }

    .sliderContainer {
      display: flex;
      flex-direction: column;
      gap: 16px;
      pointer-events: none;
      justify-content: center;
      width: 100%;

      .banner {
        display: flex;
        align-items: center;
        gap: 80px;
        overflow: clip;
        animation: marquee-move-text 20s linear infinite;
        padding-left: 80px;
        width: max-content;

        &.reverse {
          animation-direction: reverse;
        }

        .bannerDetail {
          display: flex;
          gap: 30px;
          align-items: center;
          font-weight: 700;

          p {
            color: var(--white);
            font-size: 26px;
            font-weight: 300;
            line-height: 33px;
            max-width: 300px;
            font-family: var(--font-sora);

            @media screen and (min-width: 1000px) {
              font-size: 30px;
              line-height: 35px;
              letter-spacing: 0.04em;
            }

            @media screen and (min-width: 1200px) {
              font-size: 45px;
              line-height: 50px;
            }
          }

          span {
            font-family: var(--font-avant);
            paint-order: stroke fill;
            font-size: 45px;
            line-height: 50px;
            -webkit-text-stroke: 2px var(--secondary);
            font-weight: 700;
            color: var(--primary);

            @media screen and (min-width: 1000px) {
              -webkit-text-stroke: 4px var(--secondary);
              font-size: 120px;
              line-height: 150px;
            }

            @media screen and (min-width: 1200px) {
              font-size: 200px;
              line-height: 210px;
            }
          }
        }
      }
    }
  }
}

@keyframes marquee-move-text {
  to {
    transform: translateX(-50%);
  }
}

.fadeoutHorizontal {
  mask-image: linear-gradient(
    to right,
    transparent,
    var(--primary) 5rem,
    var(--primary) calc(100% - 5rem),
    transparent
  );
}
