@import 'main.scss';
.commentCarouselContainer {
  min-height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;

  article {
    max-width: 500px;
    margin: 0 auto;

    @media screen and (min-width: 1000px) {
      max-width: 750px;
    }

    @media screen and (min-width: 1200px) {
      max-width: 850px;
    }

    @media screen and (min-width: 1600px) {
      max-width: 1020px;
    }
  }
}

.full {
  padding-top: 25vh;
  background-color: var(--primary);
}

.isoLogoContainer {
  min-height: 70vh;
  height: max-content;
  position: relative;
  overflow: hidden;
}

.dynamicSectionBlogPost {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.pageWrapper {
  position: relative;
  width: 100vw;
  overflow-x: clip;
}

.aboutBgPrimary {
  width: 100%;
  height: 100%;
}

.transitionContainer {
  position: relative;
  height: auto;
}
