@import 'main.scss';
.wrap {
  position: relative;
  padding: 70px 0px 100px;
  height: auto;
  overflow-x: hidden;

  @media screen and (min-width: 1000px) {
    padding: 120px 0px 200px;
  }

  &.primary {
    background-color: var(--primary);
  }

  &.transparent {
    background-color: transparent;
  }

  @media screen and (min-width: 1600px) {
    padding: 200px 0px 300px 0px;
  }

  h2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 32px;

    :first-child {
      color: transparent;
      background-image: linear-gradient(
        -90deg,
        rgba(255, 255, 255, 0.5) 50%,
        var(--white) 50%
      );
      padding-left: 8px;
      padding-top: 14px;

      @media screen and (min-width: 1001px) {
        line-height: 121px;
      }

      @media screen and (min-width: 1201px) {
        line-height: 148px;
      }

      @media screen and (min-width: 1601px) {
        line-height: 173px;
      }
    }

    p {
      color: var(--primary);
      -webkit-text-stroke-color: var(--background);
    }

    :last-child {
      -webkit-text-stroke: 3px var(--white);
      paint-order: stroke fill;
      color: var(--primary);

      @media screen and (min-width: 1000px) {
        -webkit-text-stroke: 5px var(--white);
      }

      @media screen and (min-width: 1200px) {
        -webkit-text-stroke: 6px var(--white);
      }
    }
  }
}
