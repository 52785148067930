@import 'main.scss';
.sectionContainer {
  width: 100%;
  height: 100%;
  margin: 50px auto;
  object-fit: cover;
  overflow: hidden;

  @media screen and (min-width: 1000px) {
    padding-top: 142px;
    max-width: unset;
    width: 100%;
    height: 100%;
    aspect-ratio: 1.8 / 1;
  }

  @media screen and (min-width: 1200px) {
    aspect-ratio: 1.9 / 1;
  }

  @media screen and (min-width: 1600px) {
    aspect-ratio: 2 / 1;
  }

  .bannerImg,
  img {
    width: 100%;
    height: 100%;
    max-width: unset;
    min-height: 250px;
  }
}
