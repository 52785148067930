@import 'main.scss';
.section {
  background-color: var(--primary);
  height: auto;
  width: 100%;
  padding: 100px 0px 50px 0px;
  .container {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 48px;

      :first-child {
        transform: translateX(250px);
        color: transparent;
        background-image: linear-gradient(
          -90deg,
          rgba(255, 255, 255, 0.5) 50%,
          var(--white) 50%
        );
      }

      :last-child {
        transform: translateX(-250px);
        -webkit-text-stroke: 3px var(--white);
        paint-order: stroke fill;
        color: var(--primary);

        @media screen and (min-width: 1000px) {
          -webkit-text-stroke: 5px var(--white);
        }

        @media screen and (min-width: 1200px) {
          -webkit-text-stroke: 6px var(--white);
        }
      }
    }

    .cardsContainer {
      width: 100%;
      max-width: calc(400px + (24px * 2));
      height: auto;
      display: flex;
      flex-direction: column;
      padding: 0 24px 4px 24px;
      gap: 24px;
    }
  }
}

.card {
  display: flex;
  justify-content: center;
  perspective: 1000px;
  user-select: none;
  width: 100%;
  height: 170px;

  .card_inner {
    display: flex;
    justify-content: center;
    position: relative;
    transform-style: preserve-3d;
    border-radius: 38px;
    width: 100%;
    height: 100%;
    z-index: 4;

    .card_inner_front,
    .card_inner_back {
      position: absolute;
      width: 100%;
      height: 100%;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      border-radius: 38px;
      overflow: hidden;
      z-index: 5;
    }

    .card_inner_front {
      background-color: var(--white);
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      height: 100%;
      width: 100%;
      text-align: center;
      position: relative;
      overflow: hidden;

      .card_information {
        display: flex;
        flex-direction: column;
        padding: 49px 0px 49px 24px;
        align-items: flex-start;
        justify-content: center;
        z-index: 2;
        max-width: 160px;

        @media screen and (min-width: 400px) {
          padding: 47.5px 0px 47.5px 32px;
          max-width: 190px;
        }

        h2 {
          color: var(--primary);
          font-family: var(--font-avant);
          font-style: normal;
          font-weight: 700;
          font-size: 32px;
          line-height: 40px;
          text-transform: uppercase;
          margin-bottom: 0px;

          @media screen and (min-width: 400px) {
            font-size: 40px;
            line-height: 45px;
          }
        }

        p {
          text-transform: uppercase;
          color: var(--primary);
          font-family: var(--font-avant);
          font-size: 16px;
          line-height: 22px;
          font-weight: 700;
          text-align: left;

          @media screen and (min-width: 400px) {
            font-size: 20px;
            line-height: 20px;
          }
        }
      }

      .card_image {
        height: 100%;
        position: absolute;
        top: 0;
        inset: 0;

        img {
          width: 100%;
          height: auto;

          @media screen and (min-width: 400px) {
            transform: translateY(-8%);
          }
        }
      }
    }

    .card_inner_back {
      background-color: var(--white);
      transform: rotateY(180deg);
      width: 100%;
      height: 100%;
      z-index: 8;
    }
  }

  .card_glow {
    position: absolute;
    width: 100%;
    height: calc(100% + 4px);
    background-color: var(--secondary);
    z-index: 3;
    pointer-events: none;
    border-radius: 38px;
  }
}

.cardPopup {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
  padding: 24px;
  width: 100%;

  .content {
    width: 450px;
    background-color: var(--white);
    border-radius: 28px;
    padding: 24px;
    position: relative;
    border-bottom: var(--secondary) 4px solid;
    height: auto;

    .infoContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .name {
      color: var(--secondary);
      font-family: var(--font-avant);
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 40px;
      text-transform: uppercase;
    }

    .role {
      margin-top: 4px;
      color: var(--primary);
      font-family: var(--font-avant);
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.8px;
      text-transform: uppercase;
    }

    .description {
      margin-top: 12px;
      font-size: 16px;
      line-height: 20px;
      font-family: var(--font-sora);
      font-weight: 300;
      color: var(--primary);
    }

    a {
      position: absolute;
      top: 24px;
      right: 24px;
      width: 44px;
      height: 44px;
      border-radius: 50%;
      overflow: hidden;
      z-index: 5;
      transition: background-color 0.5s;

      @media screen and (hover: hover) {
        &:hover {
          background-color: #af2eff;

          svg path {
            fill: var(--white);
          }
        }
      }

      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 44px;
        height: auto;
      }
    }
  }
}
