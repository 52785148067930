@import 'main.scss';
.card {
  position: relative;
  font-family: var(--font-sora);
  color: var(--white);
  display: grid;
  max-width: 690px;
  margin: 0 auto;

  .quotationMarks {
    position: absolute;
    top: -25px;
    height: auto;
    width: 50px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
  }

  .text {
    font-family: var(--font-sora);
    padding: 55px 48px;
    border-radius: 20px;
    background-color: var(--white);
    font-size: 16px;
    font-weight: 300;
    line-height: 22px;
    text-align: center;
    color: var(--primary);
    @media screen and (min-width: 1000px) {
      font-size: 20px;
      line-height: 28px;
      min-height: 250px;
    }
    @media screen and (min-width: 1200px) {
      min-height: 306px;
    }
    @media screen and (min-width: 1900px) {
      min-height: 250px;
    }
  }

  .data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
    margin-top: -45px;
    align-items: center;

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 4px;
    }

    picture {
      width: 102px;
      height: 102px;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }

    h3 {
      font-size: 24px;
      font-weight: 600;
      line-height: 29px;
      text-align: center;
    }

    span {
      font-size: 20px;
      font-weight: 400;
      line-height: 25px;
      text-align: center;
      color: #dcdcdc;
    }
  }
}

.backgroundWhite {
  .data div {
    h3,
    span {
      color: var(--Primary, #17082f);
    }
  }
}

.alone {
  .quotationMarks {
    height: 95px;

    @media screen and (min-width: 1000px) {
      width: 98px;
    }
  }

  .text {
    @media screen and (min-width: 1000px) {
      padding: 100px 48px;
    }

    @media screen and (min-width: 1200px) {
      font-size: 26px;
      line-height: 33px;
    }
  }

  h3 {
    font-size: 26px;
    font-weight: 300;
    line-height: 33px;
  }

  span {
    font-size: 16px;
    font-weight: 300;
    line-height: 22px;
  }
}
