@import 'main.scss';
.carouselWrap {
  background-color: var(--primary);
  height: 100%;
  padding: 300px 0px;
  margin-bottom: 70px;

  @media screen and (min-width: 401px) {
    margin-bottom: 100px;
  }

  @media screen and (min-width: 1001px) {
    margin-bottom: 205px;
  }

  @media screen and (min-width: 1201px) {
    margin-bottom: 208px;
  }

  @media screen and (min-width: 1601px) {
    margin-bottom: 180px;
  }

  @media screen and (min-width: 1901px) {
    margin-bottom: 240px;
  }

  > div > div {
    > button {
      display: none !important;
    }
    > div > div > div {
      padding: 26px 19px;
    }
    ul {
      bottom: unset;
      width: 100%;
      display: flex !important;
      justify-content: center;
      gap: 16px;
      padding: 0 24px;
      padding-top: 22px;
      align-items: center;
      margin: 0 auto;

      @media screen and (min-width: 1000px) {
        padding-top: 54px;
      }

      @media screen and (min-width: 1200px) {
        padding-top: 72px;
      }

      @media screen and (min-width: 1600px) {
        padding-top: 124px;
      }

      li {
        width: 100%;
        max-width: 57px;
        cursor: none;

        button {
          cursor: none;
          width: 100%;
          max-width: 57px;
          height: 6px;
          padding: unset;
          &::before {
            content: "";
            cursor: none;
            width: 100%;
            max-width: 57px;

            height: 6px;
            border: 1px solid var(--secondary, #af2eff);
            opacity: 1;
          }
        }
      }
      :global(.slick-active button:before) {
        background-color: var(--secondary, #af2eff);
      }
    }
  }
}
