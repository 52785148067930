@import 'main.scss';
.wordFlip {
  -webkit-text-stroke-width: 3px;
  -webkit-text-stroke-color: var(--primary);
  color: var(--background);
  paint-order: stroke fill;

  text-overflow: nowrap;
  white-space: nowrap;

  @media screen and (min-width: 1025px) {
    -webkit-text-stroke-width: 5px;
  }

  @media screen and (min-width: 1200px) {
    -webkit-text-stroke-width: 6px;
  }
}
