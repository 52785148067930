@import 'main.scss';
.articlesContainer {
  padding-bottom: 70px;

  > div {
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    h2 {
      font-family: var(--font-avant);
      font-size: 35px;
      font-weight: 700;
      line-height: 45px;
      letter-spacing: 0.64px;
      text-align: center;
      margin-bottom: 24px;

      @media screen and (min-width: 1000px) {
        font-size: 45px;
        line-height: 50px;
        margin-bottom: 48px;
      }
    }

    .subtitle {
      font-size: 32px;
      font-weight: 700;
      line-height: 40px;
      color: #2f343d;
      text-align: start;
      margin-bottom: 32px;

      @media screen and (min-width: 1200px) {
        font-size: 35px;
        line-height: 45px;
      }
    }

    > ul {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: center;
      gap: 24px;

      @media screen and (min-width: 1000px) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
      }

      > li {
        display: flex;
        flex: 1 1 290px;
        max-width: 100%;

        @media screen and (min-width: 1000px) {
          flex-basis: 100%;
        }

        > * {
          flex: 1;
        }
      }
    }
  }
}
