@import 'main.scss';
.headerContainer {
  font-family: var(--font-sora);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 99;
  pointer-events: none;

  & > * {
    pointer-events: all;
  }

  .logo {
    height: 40px;
    width: auto;
    z-index: 99;
    position: relative;

    @media screen and (min-width: 1000px) {
      height: 50px;
    }

    svg {
      height: 100%;
      width: auto;
    }

    svg g path[data-name="Blink"] {
      animation: Blink 1.435s infinite backwards;
    }

    @keyframes Blink {
      from {
        visibility: hidden;
      }
      50% {
        visibility: hidden;
      }
      to {
        visibility: visible;
      }
    }
  }

  .actionsContainer {
    display: flex;
    align-items: center;
    gap: 32px;
    position: relative;
    justify-content: flex-end;
    z-index: 30;
    height: auto;
  }

  .logoContainer {
    transition:
      opacity 0.3s ease-in-out,
      transform 0.2s ease-in-out;
  }

  .logoSticky {
    position: absolute;
    opacity: 0;
    transform: scale(0.5);
    transition:
      opacity 0.3s ease-in-out,
      transform 0.2s ease-in-out;
    z-index: 99;

    &.colorActive {
      svg {
        path {
          color: var(--white) !important;
          fill: var(--white) !important;
        }
      }
    }
  }

  .hidden {
    opacity: 0;
    transform: scale(0.5);
    pointer-events: none;
  }

  .visible {
    opacity: 1;
    transform: scale(1);
  }

  .underLine {
    path {
      &:first-child {
        opacity: 0;
        animation: fadeInOut 0.9s infinite;
      }
    }
  }

  @keyframes fadeInOut {
    0%,
    100% {
      opacity: 0;
    }

    50% {
      opacity: 1;
    }
  }

  .lens {
    border: 1px solid var(--primary);
    height: 44px;
    width: min-content;
    border-radius: 22px;
    display: none;
    align-items: center;
    justify-content: center;
    padding-left: 9px;
    padding-right: 5px;
    gap: 4px;
    overflow: hidden;

    @media screen and (min-width: 1000px) {
      display: flex;
    }

    @media screen and (hover: hover) {
      &:hover {
        transform: scale(1.05);
        transition: all 600ms;
      }
    }

    input {
      opacity: 0;
      background-color: transparent;
      font-family: var(--font-sora);
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      outline: none;
      color: var(--primary);
      margin-right: -172px;
      transition: all 800ms;

      &::placeholder {
        color: var(--primary);
      }
    }

    .icon {
      height: 24px;
      width: 24px;
    }
  }

  .active {
    padding: 10px 16px;
    max-width: 330px;
    width: 100%;
    background-color: var(--white);

    @media screen and (hover: hover) {
      &:hover {
        transform: none;
      }
    }

    input {
      margin-right: 0;
      opacity: 1;
      transition: all 1s;
    }
  }

  &.blog {
    background-color: #fbf5ffb2;
    backdrop-filter: blur(10px);
    max-width: none;
    padding: 20px 24px 12px 24px;

    .btnsMenu {
      display: none;
      align-items: center;
      gap: 48px;

      @media screen and (min-width: 1000px) {
        display: flex;
      }

      li,
      li a {
        display: flex;
        align-items: center;
        // gap: 12px;
        font-size: 18px;
        font-weight: 600;
        line-height: 23px;
        text-transform: uppercase;
        transition: all 300ms;

        @media screen and (hover: hover) {
          &:hover {
            .arrow {
              transition: all 300ms;
              animation: squeeze 500ms linear;

              &::before {
                transition: all 300ms;
                transform-origin: top left;
                animation: squeezeBefore 500ms linear 200ms;
              }

              &::after {
                transition: all 300ms;
                transform-origin: top right;
                animation: squeezeAfter 500ms linear 200ms;
              }
            }
          }

          @keyframes squeeze {
            0% {
            }

            30% {
              transform: scaleY(0.5) translateY(10px);
              bottom: 1px;
            }

            45% {
              transform: scaleY(1.2) translateY(-8px);
            }

            55% {
              transform: scaleY(0.5) translateY(-10px);
            }

            100% {
              transform: scaleY(1) translateY(0);
            }
          }

          @keyframes squeezeBefore {
            0% {
            }

            30% {
              transform: scaleY(0.8) rotate(20deg);
            }

            45% {
              transform: scaleY(0.8) rotate(25deg);
            }

            55% {
              transform: scaleY(0.8) rotate(25deg);
            }

            100% {
              transform: scaleY(1) rotate(45deg);
            }
          }

          @keyframes squeezeAfter {
            0% {
            }

            30% {
              transform: scaleY(0.8) rotate(-30deg);
            }

            45% {
              transform: scaleY(0.8) rotate(-25deg);
            }

            55% {
              transform: scaleY(0.8) rotate(-25deg);
            }

            100% {
              transform: scaleY(1) rotate(-45deg);
            }
          }
        }
      }

      .arrowContainer {
        transform: rotate(45deg);
        position: relative;
        width: 22px;
        height: 24px;
      }

      .arrow {
        position: absolute;
        width: 2px;
        height: 20px;
        border-radius: 2px;
        background-color: var(--primary);
        border-top: 2px solid var(--background);
        left: calc(50% - 2px);

        &::before,
        &::after {
          content: "";
          position: absolute;
          width: 2px;
          height: 10px;
          background-color: var(--primary);
          top: 0;
          border-radius: 2px;
        }

        &::before {
          transform-origin: top left;
          transform: rotate(45deg);
          margin-top: -0.5px;
          margin-left: 0.8px;
        }

        &::after {
          transform-origin: top right;
          transform: rotate(-45deg);
          margin-left: -0.5px;
          margin-top: -0.5px;
        }
      }
    }
  }

  .logoBlog {
    position: relative;
    top: 7px;
  }

  button {
    margin-left: 8px;
  }

  .hoverLine {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 2px;
      bottom: -8px;
      left: 0;
      background-color: #000000;
      transform-origin: bottom right;
      transition: transform 0.25s ease-out;
    }

    @media screen and (hover: hover) {
      &:hover {
        &::after {
          transform: scaleX(1);
          transform-origin: bottom left;
        }
      }
    }
  }
}
