@import 'main.scss';
.overlayContainer {
  display: none;

  &.active {
    display: flex;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100dvh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
    padding: 0;
    overflow-y: scroll;
    background-color: #fff;

    @media screen and (min-width: 1000px) {
      padding: 24px;
      overflow-y: hidden;
      background-color: rgba(0, 0, 0, 0.3);
    }

    .dialogContainer {
      position: relative;
      max-width: 1400px;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      padding: 24px;
      background: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      &.stepFour {
        gap: 30px;

        @media screen and (min-width: 1000px) {
          gap: 0px;
        }
      }

      @media screen and (min-width: 1000px) {
        height: 680px;
        border-radius: 20px;
        padding: 40px 50px;

        &.stepFive {
          gap: 24px;
        }
      }

      @media screen and (min-width: 1600px) {
        max-width: 1500px;
        padding: 50px;
        height: 700px;
      }

      .choicesQuestions {
        display: flex;
        flex-direction: column;
        gap: 0px;
        width: 100%;

        @media screen and (min-width: 1000px) {
          gap: 30px;
        }

        .choicesClose {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          width: 100%;
          position: relative;

          @media screen and (min-width: 1000px) {
            justify-content: center;
            height: 50px;
          }

          div:last-child {
            position: absolute;
            right: 0px;
            top: 0px;

            @media screen and (min-width: 1000px) {
              top: auto;
            }
          }
        }

        .step {
          margin: auto 0;
          height: 100%;
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 24px;
          position: relative;

          padding-top: 100px;

          @media screen and (min-width: 1000px) {
            padding-top: 0px;
            gap: 96px;
          }

          @media screen and (min-width: 1200px) {
            height: auto;
          }

          .dialogContent {
            top: 100px;
            display: flex;
            flex-direction: column;
            left: 0;
            right: 0;
            margin: 0 auto;
            max-width: 450px;
            gap: 24px;
            opacity: 0;
            user-select: none;

            @media screen and (min-width: 1000px) {
              max-width: none;
              top: 0;
              bottom: 0;
              height: fit-content;
              gap: 90px;
            }

            &.active {
              z-index: 99;
              user-select: auto;
              animation: fadeIn ease 1.5s forwards;
            }
          }
        }
      }

      .navigationBtnContainer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        align-items: center;
        &.stepFourSubmit {
          padding-bottom: 24px;

          @media screen and (min-width: 1000px) {
            padding-bottom: 0px;
          }
        }

        :last-child {
          margin-left: auto;
        }
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.canvas {
  position: absolute;
  height: 100%;
  width: 100%;
  display: none;
  border-radius: 16px;

  &.active {
    height: 100%;
    position: absolute;
    inset: 0;
    display: flex;
  }

  canvas {
    width: 100%;
    border-radius: 16px;
  }

  .canvasText {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: left;
    gap: 12px;
    padding: 0px 24px;
    top: 112px;

    max-width: 1150px;

    @media screen and (min-width: 1000px) {
      top: 100px;
    }

    @media screen and (min-width: 1200px) {
      top: 150px;
      text-align: center;
    }

    h2 {
      font-family: var(--font-avant);
      font-weight: 700;
      font-size: 25px;
      line-height: 30px;
      text-transform: uppercase;
      color: var(--primary);
      padding-right: 72px;
      -webkit-text-stroke: 1px var(--primary);

      @media screen and (min-width: 1000px) {
        padding-right: 0;
        font-size: 50px;
        line-height: 60px;
      }

      @media screen and (min-width: 1200px) {
        font-size: 60px;
        line-height: 70px;
      }

      @media screen and (min-width: 1600px) {
        font-size: 70px;
        line-height: 80px;
      }
    }

    p {
      font-family: var(--font-sora);
      font-size: 16px;
      font-weight: 300;
      line-height: 22px;

      @media screen and (min-width: 1000px) {
        font-size: 26px;
        line-height: 33px;
      }
    }
  }
}
