@import 'main.scss';
.bannerContainer {
  width: 100%;
  height: 100%;
  margin: 0 auto 48px auto;
  @media screen and (min-width: 1000px) {
    padding: 0px;
  }
  img {
    width: auto;
    height: auto;
    margin: 0 auto;
  }

  &.noStyle {
    img {
      width: auto;
      height: auto;
      aspect-ratio: auto;
      max-width: 70vw;
      max-height: 80vh;
    }
  }

  &.stylized {
    max-width: 932px;
    max-height: 348px;
    img {
      max-height: 348px;
      width: 100%;
      border-radius: 20px;
      @media screen and (min-width: 1000px) {
        border-radius: 50px;
      }
    }
  }
}
