@import 'main.scss';
.cards {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 32px;
  padding: 0 24px;
  justify-content: center;
  margin: auto;
  align-items: center;
  max-width: 700px;

  @media screen and (min-width: 1000px) {
    flex-direction: row;
    flex-wrap: wrap;
  }

  @media screen and (min-width: 1200px) {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    gap: 24px;
    flex-wrap: nowrap;
    width: 1400px;
    max-width: none;
    margin: 0;
  }

  .expertiseCard {
    transform: rotate(0);
    height: 430px;

    @media screen and (min-width: 1000px) {
      height: 460px;
    }

    @media screen and (min-width: 1200px) {
      position: relative;
    }
  }
}
